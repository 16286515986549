import ProductImagesManager from "@/components/standalone/product-images-manager/ProductImagesManager";
import { ReactElement } from "react";
import Layout from "./layout";

const Page = ({ ...props }) => {

    return (
        <div className="p-4 flex-1">
            Home
        </div>
    )
}

Page.getLayout = function getLayout(page: ReactElement) {
    return (
        <Layout>
            {page}
        </Layout>
    )
}

export default Page;
